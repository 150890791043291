<template>
  <div>
    <v-snackbar
      content-class="centered"
      absolute
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-container fluid>
      <v-row class="header-title-actions">
        <v-col cols="12" sm="6" md="6">
          <h1 class="page-title">{{ $t("features.title") }}</h1>
        </v-col>

        <v-col class="align-end" cols="12" sm="6" md="6">
          <v-btn
            color="buttons"
            @click="showAddDialog"
            :loading="loading"
          >
            <v-icon left> fas fa-plus </v-icon>
            {{ $t("features.add") }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-5">
        <!-- <v-col cols="12" sm="12" md="12">
          <search />
        </v-col> -->

        <v-col cols="12" sm="12" md="12">
          <files-table
            @menuClick="handleMenuActions($event)"
            :items="characteristics"
            :showEdit="true"
            :showDelete="true"
            :loading="loading"
            :headers="headers"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-if="addDialog" persistent max-width="700px" v-model="addDialog">
      <add-characteristic
        :originalItem="selectedItem"
        @success="added"
        @cancel="addDialog = false"
      />
    </v-dialog>

    <v-dialog max-width="500px" v-if="deleteDialog" v-model="deleteDialog">
      <delete-item
        ref="deleteComponent"
        :name="selectedItem.name"
        @success="confirmDelete"
        @cancel="deleteDialog = false"
      />
    </v-dialog>
  </div>
</template>
<script>
import { db, fb } from "@/firebase";
import addCharacteristic from "./add-characteristics";
import deleteItem from "@/components/delete";
import filesTable from "@/components/files-table";
import { mapActions, mapState } from "vuex";

export default {
  name: "Characteristic",
  components: {
    addCharacteristic,
    deleteItem,
    filesTable,
  },
  data() {
    return {
      characteristics: [],
      headers: [
        {
          text: this.$t("headers.name"),
          value: "name",
          width: "150px",
        },
        {
          text: this.$t("headers.createdAt"),
          value: "createdAt",
          width: "200px",
        },
        {
          text: "Tags",
          value: "items",
        },
        {
          value: "options",
          sortable: false,
          align: "end",
        },
      ],
      snackbarText: "",
      snackbar: false,
      loading: true,
      addDialog: false,
      selectedItem: null,
      deleteDialog: false,
    };
  },

  computed: {
    ...mapState(["groupId"]),
  },

  methods: {
    ...mapActions(["cleanSearch"]),
    handleMenuActions(payload) {
      switch (payload.action) {
        case "edit":
          this.edit(payload.item);
          break;
        case "delete":
          this.showDeleteDialog(payload.item);
          break;
      }
    },
    showAddDialog() {
      this.selectedItem = null;
      this.addDialog = true;
    },
    edit(item) {
      this.selectedItem = item;
      this.addDialog = true;
    },
    showDeleteDialog(item) {
      this.selectedItem = item;
      this.deleteDialog = true;
    },
    confirmDelete() {
      this.$refs.deleteComponent.loading = true;
      db.collection("characteristics")
        .doc(this.selectedItem[".key"])
        .delete()
        .then((response) => {
          this.snackbarText = this.$t("features.deleted");
          this.snackbar = true;
          this.$refs.deleteComponent.loading = false;
          this.deleteDialog = false;
        })
        .catch((error) => {
          this.snackbarText = this.$tc("messages.unknown");
          this.snackbar = true;
          this.$refs.deleteComponent.loading = false;
        });
    },
    added() {
      this.addDialog = false;
      this.snackbarText = this.selectedItem
        ? this.$t("features.updated")
        : this.$t("features.added");
      this.snackbar = true;
    },
  },
  mounted() {
    this.cleanSearch();

    this.$binding(
      "characteristics",
      db.collection("characteristics").where("groupId", "==", this.groupId)
    ).then((res) => {
      this.loading = false;
    });
  },
};
</script>
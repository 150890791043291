<template>
  <div>
    <v-snackbar content-class="centered" top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-card class="d-flex flex-column">
      <v-card-title class="grid-close">
        <span class="headline"
          >{{ originalItem ? $t('dialog.edit') : $t('dialog.add') }} {{$t('features.title')}}</span
        >
        <v-btn
          style="right: 10px; top: 10px;  border-radius : 30px!important"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
          v-if="!loading"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="pa-5">
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="characteristic.name"
                :label="$t('dialog.name')"
                :loading="loading"
                clearable
                rounded
                filled
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="newItem"
                :label="$t('dialog.new_feature')"
                :hint="$t('features.enter_to_add')"
                persistent-hint
                :loading="loading"
                v-on:keyup.enter="add"
                clearable
                rounded
                filled
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-chip
                v-for="(item, index) of characteristic.items"
                :key="index"
                class="ma-2"
                close
                color="primary"
                @click:close="remove(index)"
              >
                {{ item }}
              </v-chip>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col cols="12" sm="12" md="12">
              <v-row justify="end">
                <v-btn
                  :loading="loading"
                  @click="originalItem ? update() : save()"
                  color="buttons"
                  >{{$t('button.save')}}</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";

export default {
  name: "add-product",
  props: ["originalItem"],
  data() {
    return {
      characteristic: {
        name: "",
        items: [],
      },
      snackbarText: "",
      newItem: "",
      snackbar: false,
      loading: false,
    };
  },
  computed: {
    ...mapState(["user", "groupId"]),
  },

  methods: {
    add() {
      if (this.newItem) {
        this.characteristic.items.push(this.newItem);
        this.newItem = "";
      }
    },
    remove(index) {
      this.characteristic.items.splice(index, 1);
    },
    update() {
      if (this.characteristic.name && this.characteristic.items.length > 0) {
        this.loading = true;
        let data = {
          name: this.characteristic.name,
          items: this.characteristic.items,
          modifiedAt: new Date(),
          modifiedBy: this.user.uid,
        };

        db.collection("characteristics")
          .doc(this.originalItem[".key"])
          .update(data)
          .then((response) => {
            this.loading = false;
            this.$emit("success");
          })
          .catch((error) => {
            this.loading = false;
            this.snackbarText =
              this.$t('messages.unknown');
            this.snackbar = true;
          });
      } else {
        this.snackbarText =
         this.$t('features.insert_name');
        this.snackbar = true;
      }
    },
    save() {
      if (this.characteristic.name && this.characteristic.items.length > 0) {
        this.loading = true;
        let data = {
          name: this.characteristic.name,
          items: this.characteristic.items,
          createdAt: new Date(),
          createdBy: this.user.uid,
          groupId: this.groupId,
        };

        db.collection("characteristics")
          .add(data)
          .then((response) => {
            this.loading = false;
            this.$emit("success");
          })
          .catch((error) => {
            this.loading = false;
            this.snackbarText =
              this.$t('messages.unknown');
            this.snackbar = true;
          });
      } else {
        this.snackbarText =
          this.$t('features.insert_name');
        this.snackbar = true;
      }
    },
  },
  mounted() {
    if (this.originalItem) {
      this.characteristic = Object.assign({}, this.originalItem);
    }
  },
};
</script>

<style>
</style>